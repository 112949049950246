body {
  margin: 0;
  font-family: 'Montserrat', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url("./styles/output.css");
.toggleDarkBtn {
  position: relative;
  display: inline-block;
  width: 65px;
  height: 41px;
}
.toggleDarkBtn input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slideBtnTg {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(241, 40, 40);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.slideBtnTg:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 5px;
  bottom: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  background-color: white;
}
input:checked + .slideBtnTg {
  background-color: #1d1e1f;
}
input:focus + .slideBtnTg {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slideBtnTg:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slideBtnTg.round {
  border-radius: 34px;
}
.slideBtnTg.round:before {
  border-radius: 50%;
}